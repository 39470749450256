// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-pro/css/all.min.css";

@import "/assets/styles/utils.scss";

@import "/public/front/scss/ekko-lightbox.scss";

@import "/public/front/scss/mine.scss";


