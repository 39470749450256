//font-family: 'Anton', sans-serif;
//font-family: 'Open Sans', sans-serif;
//font-family: 'Oswald', sans-serif;

body {
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}

.bg-gray-light {
  background: #ededed !important;
}

strong {
  font-weight: bold;
}

@media (max-width: 992px) {
  .navbar {
    background: rgba(10, 10, 10, 0.72) !important;
  }
}
.navbar {
  background: rgba(10, 10, 10, 0);
  //  background: rgba(33, 33, 33, 0);
  text-transform: uppercase;
  color: #FFF !important;
  transition: 400ms;
  font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;

  .navbar-toggler,
  .navbar-toggler-icon {
    color: rgba(255, 255, 255, 1) !important;
  }

  .dropdown-menu {
    background: rgba(10, 10, 10, 0.72);
    border-top: 2px solid #c00000;

    .dropdown-item {
      background: transparent;
    }
  }

  &.scrolled {
    background: rgba(10, 10, 10, 0.72);
  }

  .navbar-nav {
    a {
      font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
      font-weight: 100;

      color: rgba(255, 255, 255, 1) !important;
      font-size: 17px;
      transition: 400ms;

      &:hover {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .navbar-brand {
    img {
      height: 50px;
    }
  }
}
@media (max-width: 768px) {

  .sticky-top {
    position: fixed;
    top: 0;
    z-index: 1020;
    width: 100%;
  }
}

.cont-first {
  margin-top: -76px;
}



.divider1 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMjBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 120px;
  bottom: 0;
  height: 120px;
  width: 100%;
  z-index: 10;
  transform: rotateX(180deg);
}

.divider2 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNDVweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik02NDAgMTQwTDEyODAgMEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik02NDAgOThsNjQwLTk4SDB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 245px;
  top: 0;
  height: 245px;
  z-index: 1;
}

.divider3 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIzMDNweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik02NDAgMTM5TDAgMHYxNDBoMTI4MFYwTDY0MCAxMzl6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTY0MCAxMzlMMCA0MnY5OGgxMjgwVjQybC02NDAgOTd6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 303px;
  bottom: 0;
  height: 303px;
  z-index: 1;
}

.homehero {
  background-color: #7c7c7c;
  background-position: center;
  background-size: 100%;
  background-size: cover;

  position: relative;
  text-align: center;
  color: #FFF;

  &.h640 {
    min-height: 640px;
  }

  &.title_page {
    padding: 120px 0 60px 0;
    text-transform: uppercase;
  }

  h1 {
    color: #FFF;
    font-family: 'Glegoo', Georgia, "Times New Roman", serif;
    font-size: 80px;

    &.title {
      font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
      font-size: 95px;
      color: #FFF !important;
      letter-spacing: 10px;
      text-shadow: 0.08em 0.08em 0em rgba(0, 0, 0, 0.4);
      padding-top: 200px;
    }
  }

  img {
    width: 100%;
    max-width: 400px;
    margin-top: 200px;
    margin-left: -40%;
  }

  .divider1 {
    position: absolute;
    bottom: 0;
  }
}

h2 {
  &.shlashed {
    font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
    font-size: 78px;
    color: #333333;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    display: inline-block;

    &:after {
      content: " ";
      background-image: url("/front/images/design/setl-slash.svg");
      background-position: center bottom;
      background-size: 150px 45px;
      background-repeat: no-repeat;
      filter: saturate(0%) brightness(200%) contrast(0%);
      width: 150px;
      height: 45px;
      position: absolute;
      top: -3px;
      left: -10px;
    }
  }
}

.item_production {
  display: inline-block;
  position: relative;
  max-height: 340px;
  transition: 400ms;
  margin: 0 auto;

  img {
    width: 100%;
    max-height: 340px;
    transition: 400ms;
  }

  span {
    display: inline-block;
    width: 100%;
    background: #000;
    color: #FFF;
    font-family: "Glegoo", Georgia, "Times New Roman", serif;
    text-align: center;
    font-size: 44px;
    padding: 10px;
    text-transform: uppercase;
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 10;
  }

  &:hover {
    img {
      filter: grayscale(1);
    }
  }
}

#historique {
  position: relative;
  padding-top: 250px;
  padding-bottom: 150px;
  margin-top: 100px;

  .divider2 {
    position: absolute;
    width: 100%;
    height: 240px;
    top: 0;
    z-index: 10;
  }

  .divider3 {
    position: absolute;
    width: 100%;
    height: 300px;
    bottom: 0;
    z-index: 10;
  }

  .container {
    position: relative;
    z-index: 50;
  }

  .bloc-dashed {
    font-family: "Fira Sans", Georgia, "Times New Roman", serif;
    font-weight: 300;
    font-size: 18px;
    border: 4px dashed #d8d8d8;
    color: rgb(102, 102, 102);

    padding: 50px !important;
    background-color: #FFF;

    strong {
      font-weight: 700;
    }


    p:first-of-type:first-letter {
      padding: 10px;
      background-color: #171515e3;
      color: white;
      font-size: 70px;
      float: left;
      margin-right: 10px;
      font-family: "Glegoo", Georgia, "Times New Roman", serif;

    }

  }
}

.liste_teams {
  border-left: 2px solid rgb(102, 102, 102);
  padding-left: 20px;
  color: rgb(102, 102, 102);
  font-family: "Fira Sans", Helvetica, Arial, Lucida, sans-serif;
  font-size: 17px;

  h4 {
    font-family: "Glegoo", Georgia, "Times New Roman", serif;
    font-size: 20px;
    color: #222;
    margin-top: 15px;
  }
}

@media print {
  .navbar {
    display: block !important;
  }
}

#contact {
  h1, .title_gle {
    font-family: "Glegoo", Georgia, "Times New Roman", serif;
    font-size: 26px;
    color: rgb(51, 51, 51);

  }
}

.footer {
  background-color: rgba(10, 10, 10, 1);
  color: #666;
  text-align: center;
  height: 54px;
  line-height: 54px;
  font-size: 14px;

  a {
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
  }
}

.text-generique {
  font-size: 14px;
  color: rgb(102, 102, 102);

  h2, h3 {
    color: #333;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }


}

.title_prod {
  text-align: center;
  font-family: 'Glegoo', Georgia, "Times New Roman", serif;
  font-size: 60px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #333;
  font-weight: 500;
  line-height: 1em;
}

.kartouch {
  font-family: 'Fira Sans', Helvetica, Arial, Lucida, sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  border: 2px dashed #969696;
  padding: 8px !important;
  color: rgb(102, 102, 102);
  margin-bottom: 30px;
}

h3.summary {
  font-family: 'Glegoo', Georgia, "Times New Roman", serif;
  font-size: 26px;
  margin-bottom: 50px;
}

.summary_paragraf {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: rgb(102, 102, 102);
  text-align: justify;
}

.copyright {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: rgb(102, 102, 102);
  text-align: justify;
  margin-bottom: 30px;
}

.casting_bloc {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: rgb(102, 102, 102);
  text-align: justify;

  h6 {
    font-size: 14px;
    font-weight: 700
  }
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  font-size: 50px;
  background: none !important;
}

.carousel-control-next-icon:before {
  background-image: none !important;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  content: "\f054";
  color: rgb(192, 0, 0);
}

.carousel-control-prev-icon:before {
  background-image: none !important;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  content: "\f053";
  color: rgb(192, 0, 0);
}


.nav_between {
  list-style: none;
  font-family: 'Glegoo', Georgia, "Times New Roman", serif;
  font-size: 17px;
  letter-spacing: 3px;
  line-height: 35px;
  border-left: 4px solid #aaaaaa;
  padding: 0px 10px 0px 10px !important;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;

  li {
    a {
      color: #c00000 !important;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.divider_nav_between {
  height: 2px;
  background: #d1d1d1;
  margin: 70px 0 70px 30px;
  width: 100%;
  max-width: 200px;
}

.liste_blocs {
  margin-left: 30px;
  color: #666666;
  font-size: 14px;

  ul {
    margin-top: 20px;
    padding: 0 0 0 14px;

    li {
      color: #888888;
      line-height: 25px;
    }
  }
}

.equipe_nom {
  text-transform: uppercase;
  font-family: 'Glegoo', Georgia, "Times New Roman", serif;
  color: #333;
  font-size: 26px;
  text-align: center;
}

.equipe_poste {
  font-style: italic;
  text-align: center;
  font-size: 20px;
}

.equipe_bio {
  text-align: justify;
}

.divider_red {
  background: #CC0000;
  height: 4px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

//.ekko-lightbox.modal .modal-dialog .modal-content .modal-body


.ekko-lightbox.modal {
  .modal-body {
    padding: 0;
  }
}


.summary_paragraf::first-letter {
  padding: 10px;
  background-color: #171515e3;
  color: white;
  font-size: 70px;
  float: left;
  margin-right: 10px;
  font-family: "Glegoo", Georgia, "Times New Roman", serif;
}

.sous-titre{
  font-size: 16px;
  text-align: center;
  font-family: "Glegoo", Georgia, "Times New Roman", serif;
  margin-bottom: 30px;
}